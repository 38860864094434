<template>
  <mdb-col xl="2" lg="3" md="4" sm="6" class=" group-top ">
    <div class="brand-logo">
      <a :href=" partner.homepage ">
        <img :alt="partner.partner_name" :src="partner.logo">
      </a>
    </div>
  </mdb-col>
</template>

<script>
import {mdbCol} from 'mdbvue';

export default {
  name: "Partner",
  components: {
    mdbCol
  },
  props: {
    partner: Object
  }
}

</script>

<style scoped>

.group-top {
  position: relative;
  overflow :hidden;
  min-height: 92px;
  padding: 0;
}
.brand-logo img {
  max-width: 185px;
}

.brand-logo {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
