<template>
  <div>
    <div class="header-area">
      <mdb-container class="text-left">
        <Rank :ideas="ideas"/>
      </mdb-container>
    </div>
    <div class="contents-area">
      <mdb-container class="text-center">
        <div class="text-center">
          <img src="@/assets/images/play_icon.png" alt="">
        </div>
        <div class="text-center title-text">
          <img src="@/assets/images/home_txt_01.png" alt="">
        </div>
        <div class="text-center sub-title-text">
          <img src="@/assets/images/home_sub_txt_01.png" alt="">
        </div>
      </mdb-container>
      <Video class="youtube-group" :youtubes="youtubes"/>
      <mdb-container class="text-center ">
        <div class="text-center title-text">
          <img src="@/assets/images/home_txt_02.png" alt="">
        </div>
        <div class="text-center">
          <img src="@/assets/images/home_sub_txt_02.png" alt="">
        </div>
        <mdb-row class="partner-group">
          <Partner :partner="partners[i]" v-for="(a,i) in partners" :key="i"/>
        </mdb-row>
      </mdb-container>
    </div>
  </div>
</template>

<script>
import {mdbContainer, mdbRow, mdbCol,} from 'mdbvue';
import Video from "../components/Video";
import Partner from "../components/Partner";
import Rank from "../components/Rank";

import firebase from "firebase/app";

export default {
  name: 'Home',
  data() {
    return {
      partners: [],
      ideas: [],
      youtubes: [],
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Rank, Partner, Video, mdbContainer, mdbRow, mdbCol,
  },
  mounted() {
    this.onLoadData()
    this.onLoadRankingData()
    this.onLoadPoliceLabVideoData()
  },
  methods: {
    onLoadRankingData() {
      const self = this;
      const db = firebase.firestore();

      db.collection("ideaBoard").orderBy("likes", "desc").limit(10).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              const _data = doc.data();
              // console.log(_data);
              _data["id"] = doc.id;
              self.ideas.push(_data);

            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
    onLoadData() {
      const self = this;
      const db = firebase.firestore();
      db.collection("Partners").orderBy('order_number')
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              const _data = doc.data();
              // console.log(_t);
              _data["key"] = doc.id;
              self.partners.push(_data);
              // console.log(self.partners);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },

    onLoadPoliceLabVideoData() {
      const db = firebase.firestore();
      const self = this;
      db.collection("Youtube")
          .orderBy('order_number', 'asc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data["id"] = doc.id;
              self.youtubes.push(_data);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
  }
}
</script>
<style scoped>

.header-area {
  height: 674px;
  background-image: url("../assets/images/bk/home_bk.png");
  background-position: center -475px;
  background-repeat: no-repeat;
}

.contents-area {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: -30px;
  background-color: white;
  padding-top: 42px;
}

.title-text {
  margin: 9px auto 7px;
}

.sub-title-text {
  margin-bottom: 34px;
}

.youtube-group {
  margin-bottom: 34px;
}

.partner-group {
  margin-bottom: 42px;
  justify-content: space-between;
}

</style>
