<template>
  <mdb-container>
    <mdb-row v-if="youtubes.length >= 3">
      <VideoScreen :youtube="youtubes[i]" v-for="(a,i) in youtubes" :key="i"/>
    </mdb-row>
    <mdb-row v-if="youtubes.length < 3">
      <VideoScreen2 :youtube="youtubes[i]" v-for="(a,i) in youtubes" :key="i"/>
    </mdb-row>
  </mdb-container>
</template>

<script>
import {mdbContainer, mdbRow, } from 'mdbvue';
import VideoScreen from "../views/admin/manage/youtube/VideoScreen";
import VideoScreen2 from "./VideoScreen2";

export default {
  name: 'Video',
  props: {
    youtubes: Array,
  },

  components: {
    VideoScreen,
    VideoScreen2,
    mdbContainer,
    mdbRow,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
