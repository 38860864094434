<template>
  <mdb-col lg="4" md="12" class="mb-2">
    <a @click="showModal = true">
      <img class="img-fluid z-depth-1"
           :src="`https://img.youtube.com/vi/${youtube.video_id}/maxresdefault.jpg`" alt="video"
           data-toggle="modal" data-target="#modal1">
    </a>
    <mdb-modal size="lg" :show="showModal" @close="showModal = false">
      <mdb-modal-body class="mb-0 p-0">
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item" :src="`https://www.youtube.com/embed/${youtube.video_id}`"
                  allowfullscreen></iframe>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-center">
        <mdb-btn outline="primary" rounded size="md" class="ml-4" @click.native="showModal = false">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-col>
</template>

<script>
import {mdbCol, mdbModal, mdbModalBody, mdbModalFooter, mdbBtn,} from 'mdbvue';

export default {
  name: "VideoScreen",
  data() {
    return {
      showModal: false
    };
  },
  props: {
    youtube: Object
  },
  components: {
    mdbCol,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
  }

}
</script>

<style scoped>

</style>