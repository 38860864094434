<template>
  <div class="rank-box">
    <div class="text-center">
      <img src="@/assets/images/idea_icon.png" alt="">
    </div>
    <div class="text-center best-idea-title">
      <img src="@/assets/images/best_idea_title.png" alt="">
    </div>
    <div class="card best-idea-blue-box">

      <mdb-carousel
          :items="items.length"
          slide
          indicators
          indicatorsColor="yellow"
          navColor="secondary"
          :interval="5000"
      >
        <template v-for="(a, i) in items" #[i+1]>
          <div :key="i">
            <mdb-card class="idea-card">
              <mdb-card-body style="padding: 20px 36px 12px">
                <img src="@/assets/images/best-label.png" alt="" style="position: absolute; top: 0; left: 0">
                <mdb-col style="padding: 0">
                  <span class="idea-title">{{ ideas[a].title }}</span>
                  <p class="idea-writer">{{ ideas[a].writer }}</p>
                  <p class="idea-contents">{{ ideas[a].contents }}</p>
                </mdb-col>
                <div class="next-btn">
                  <img src="@/assets/images/next-button.png" alt="" @click="goIdeas(ideas[a].id)">
                </div>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="idea-card" v-if="ideas.length % 2 === 1 && a + 1 === ideas.length">
              <mdb-card-body style="padding: 20px 36px 12px">
                <h5 class="no-idea">아이디어를 기다립니다.</h5>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="idea-card" v-else>
              <mdb-card-body style="padding: 20px 36px 12px">
                <img src="@/assets/images/best-label.png" alt="" style="position: absolute; top: 0; left: 0">
                <mdb-col style="padding: 0">
                  <span class="idea-title">{{ ideas[a + 1].title }}</span>
                  <p class="idea-writer">{{ ideas[a + 1].writer }}</p>
                  <p class="idea-contents">{{ ideas[a + 1].contents }}</p>
                </mdb-col>
              </mdb-card-body>
              <div class="next-btn">
                <img src="@/assets/images/next-button.png" alt="" @click="goIdeas(ideas[a + 1].id)">
              </div>
            </mdb-card>
          </div>
        </template>
      </mdb-carousel>
    </div>
    <div class="text-center" style="margin-top: 25px;">
      <router-link to="/user/login" v-if="$store.state.login === false">
        <button class="idea-button">아이디어 작성하기</button>
      </router-link>
      <router-link to="/ideaForm" v-else>
        <button class="idea-button">아이디어 작성하기</button>
      </router-link>
    </div>

  </div>
</template>

<script>
import {mdbCarousel, mdbCard, mdbCardBody, mdbCol,} from "mdbvue";

export default {
  name: "Rank",
  components: {
    mdbCarousel,
    mdbCol,
    mdbCard,
    mdbCardBody,
  },
  data() {
    return {
      isStop: false,
    };
  },
  props: {
    ideas: Array,
  },
  computed: {
    items() {
      return this.ideas
          .map((_, i) => i)
          .filter((_, i) => i % 2 === 0);
    },
  },
  mounted() {
    this.cssChange()
  },
  methods: {
    cssChange() {
      const self = this;
      const indicator = document.getElementsByClassName('carousel-indicators')[0];
      indicator.style.bottom = '-55px'

      const indicator_li = indicator.children;
      const interval = setInterval(function () {
        if (!self.isStop) {
          self.dotSize(indicator_li)
        } else {
          self.dotSize(indicator_li)
          clearInterval(interval)
        }
      }, 100);
    },

    dotSize(indicator_li) {
      const self = this;
      if (indicator_li.length > 0) {
        for (let i = 0; i < indicator_li.length; i++) {
          indicator_li[i].style.width = '15px'
          indicator_li[i].style.height = '15px'
        }
        self.isStop = true;
      } else {
        self.isStop = false;
      }
    },
    goIdeas(id) {
      this.$router.push({name: 'ideaView', query: {id: id}})
    },
  },
};
</script>

<style scoped>
.rank-box {
  width: 455px;
  padding-top: 42px;
}

.best-idea-title {
  margin: 14px 0 19px;
}

.best-idea-blue-box {
  border-radius: 10px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  background-color: #2b4999;
  width: 100%;
  height: 400px;
}

.idea-card {
  height: 169px;
  margin: 5px;
}

.idea-title {
  margin-bottom: 24px;
  object-fit: contain;
  font-family: NanumSquare, serif;
  font-size: 25px;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: #2b4999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.idea-writer {
  object-fit: contain;
  font-family: NanumSquare, serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  text-align: right;
  letter-spacing: normal;
  color: #494949;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
}

.idea-contents {
  object-fit: contain;
  margin: 0;
  font-family: NanumSquare, serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #494949;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.next-btn {
  cursor: pointer;
  position: absolute;
  right: 36px;
  bottom: 12px;
}

.no-idea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.idea-button {
  width: 223px;
  text-align: center;
  height: 46px;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 1px 4.5px 0.6px rgba(0, 0, 0, 0.4);
  background-color: #fb7e10;
  font-family: NanumSquare, serif;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  color: #fff;
}


</style>
